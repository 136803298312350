import { Box, Checkbox, Chip, Grid, Typography } from '@mui/material'
import { type PaPackage, STATUS_DELIVERED, STATUS_DELIVERING } from '../../../models/PaPackage'
import moment from 'moment/moment'
import React, { useContext } from 'react'
import { CollectionDetailContext } from '../../Collections/CollectionDetailPage'
import { useNavigate } from 'react-router-dom'
import { createLink, RouteKeys } from '../../../components/router/Routes'
import { phoneFormat } from '../../../helpers/phone'


interface IPackage {
  paPackage: PaPackage | undefined
  collectionId: string
}


export function Package ({ paPackage, collectionId }: IPackage) {
  const collectionDetailCtx = useContext(CollectionDetailContext)
  const navigate = useNavigate()
  if (!paPackage) {
    return null
  }
  const isChecked = collectionDetailCtx.isChecked(paPackage.id)
  return (
    <Box sx={{
      opacity: paPackage.status === STATUS_DELIVERED ? 0.4 : 1,
      backgroundColor: '#e5e5e5',
      minHeight: 50,
      position: 'relative',
      cursor: paPackage.status === STATUS_DELIVERED ? 'inherit' : 'pointer'
    }} onClick={() => {
      if (paPackage.status === STATUS_DELIVERED) {
        return
      }
      navigate(createLink(RouteKeys.packageDetail, { id: collectionId, packageId: String(paPackage.id) }))
    }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, backgroundColor: '#D1D1D1', padding: 0.5, paddingLeft: 1, paddingRight: 1 }}>
        {paPackage.position}
      </Box>
      {paPackage.status === STATUS_DELIVERING &&
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Checkbox checked={isChecked} onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isChecked) {
              collectionDetailCtx.addChecked(paPackage.id)
            } else {
              collectionDetailCtx.removeChecked(paPackage.id)
            }
          }}/>
        </Box>
      }
      <Grid container direction={'column'} paddingTop={3}>
        <Grid container sx={{ padding: 2 }} wrap={'nowrap'}>
          <Grid item sx={{ display: 'flex', alignItems: 'center', minWidth: 80 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
              {paPackage.expectedAt ? moment(paPackage.expectedAt).format('HH:mm') : 'N/A'}
            </Typography>
          </Grid>
          <Grid item md zeroMinWidth>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <Typography>
                  {paPackage.recepientName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ overflowWrap: 'break-word' }}>
                  {paPackage.address.street}<br/>
                  {paPackage.address.city}, {paPackage.address.zip}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {phoneFormat(paPackage.phone)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {paPackage.status === STATUS_DELIVERED &&
          <Grid item container justifyContent={'flex-end'} padding={1}>
            <Grid item>
              <Chip color={'success'} label={'Doručeno'} sx={{ borderRadius: 'unset' }} />
            </Grid>
          </Grid>
        }
      </Grid>
    </Box>
  )
}
