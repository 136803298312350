import React, { useContext, useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import { useConfig } from '../../providers/ConfigProvider'
import { RequestType, useRequest } from '../../connections/fetch/DriverApiClient'
import { UserContext } from '../../providers/UserProvider'
import { useNavigate } from 'react-router-dom'
import { RouteKeys } from '../router/Routes'


interface ILoginFormData {
  username: string
  password: string
}

interface ILoginResponse {
  token: string
}

export const LoginForm = () => {

  const fieldNameUsername = 'username'
  const fieldNamePassword = 'password'

  const userContext = useContext(UserContext)

  const [type, setType] = useState<string>('password')

  const [values, setValues] = useState<ILoginFormData>({
    username: '',
    password: ''
  })

  const navigate = useNavigate()

  const onChangeValue = (fieldName: string, value: string) => {
    if (fieldName === 'username') {
      setValues((vals) => ({
        ...vals,
        username: value
      }))
    }
    if (fieldName === 'password') {
      setValues((vals) => ({
        ...vals,
        password: value
      }))
    }
  }

  const config = useConfig()

  const { executeRequest, loading } = useRequest<ILoginResponse>({
    url: `${config.apiUrl}/api/login_check`,
    body: JSON.stringify(values),
    type: RequestType.POST,
    onSuccess: (data) => {
      const boLoginEndpointUrl = `${config.boUrl}/admin/login/driver-token`

      fetch(boLoginEndpointUrl, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + data.token,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      })
        .then(async response => {
          const cookies = response.headers.get('Set-Cookie')
          if (cookies) {
            const eachCookie = cookies.split(',')
            eachCookie.forEach(cookie => {
              document.cookie = cookie
            })
          }
          return await response.json()
        })
        .catch((error) => {
          console.error('Error:', error)
        })

      userContext.login(data.token)
    }
  })

  const submit = () => {
    executeRequest()
  }

  useEffect(() => {
    if (userContext.isAuthenticated) {
      navigate(RouteKeys.collections)
    }
  }, [userContext])

  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      submit()
    }}>
      <Grid container spacing={2} direction={'column'}>
        <Grid item>
          <TextField
            onChange={(event) => {
              const val = event.target.value
              onChangeValue(fieldNameUsername, val)
            }}
            value={values.username}
            fullWidth
            name={fieldNameUsername}
            label={'username'}
          />
        </Grid>
        <Grid item>
          <TextField
            onChange={(event) => {
              const val = event.target.value
              onChangeValue(fieldNamePassword, val)
            }}
            fullWidth
            value={values.password}
            name={fieldNamePassword}
            label={'password'}
            type={type}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => {
                  setType((type) => type === 'password' ? 'text' : 'password')
                }}>
                  <Visibility />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        {loading
          ? <Grid item container alignItems={'center'} justifyContent={'center'}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          : <Grid item>
            <Button
              sx={{
                borderRadius: 10
              }}
              variant={'contained'}
              fullWidth
              type={'submit'}
              disabled={loading}
            >
              Login
            </Button>
          </Grid>
        }
      </Grid>
    </form>
  )
}
