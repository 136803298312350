import * as React from 'react'
import { createContext, useState } from 'react'


interface IModalContext {
  openModal: (key: string, data?: any) => void
  closeModal: (key: string) => void
  isOpen: (key: string) => boolean
  getModalData: (key: string) => any
}

export const ModalContext = createContext<IModalContext>({
  openModal: (key: string, data: any) => null,
  closeModal: (key: string) => null,
  isOpen: (key: string) => false,
  getModalData: (key: string) => {}
})

export function ModalProvider ({ children }: { children: JSX.Element }) {

  const [modals, setModals] = useState<Record<string, any>>({})

  function openModal (key: string, data: any) {
    setModals((prevModals) => ({ ...prevModals, [key]: data ?? {} }))
  }

  function closeModal (key: string) {
    const newModals = { ...modals }
    if (!newModals[key]) {
      return
    }
    delete newModals[key]
    setModals(newModals)

  }

  function isOpen (key: string) {
    return !!modals[key]
  }

  function getModalData (key: string): any | null {
    if (!isOpen(key)) {
      return null
    }
    return modals[key]
  }

  return (
    <ModalContext.Provider value={{
      openModal,
      closeModal,
      isOpen,
      getModalData
    }}>
      {children}
    </ModalContext.Provider>
  )

}
