import React, { useContext, useEffect } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { RouteKeys } from '../components/router/Routes'
import { useConfig } from '../providers/ConfigProvider'
import { UserContext } from '../providers/UserProvider'


export const HomePage = () => {

  const config = useConfig()

  const userContext = useContext(UserContext)
  const navigate = useNavigate()


  useEffect(() => {
    if (userContext.isAuthenticated) {
      navigate(RouteKeys.collections)
    }
  }, [userContext])


  return (
    <Grid
      container
      direction={'column'}
      justifyContent={'center'}
      minHeight={500}
    >
      <Grid item>
        <Typography
          variant={'h3'}
          textAlign={'center'}
          color={'primary'}
          fontWeight={'bold'}
        >
          Pilulka Auto-{config.countryCode}
        </Typography>
      </Grid>
      <Grid item paddingTop={1}>
        <Typography
          textAlign={'center'}
          fontSize={20}
          fontWeight={'bold'}
          color={'dimgray'}
        >
          Aplikace pro kurýry
        </Typography>
      </Grid>
      <Grid item paddingTop={5}>
        <Typography
          textAlign={'center'}
          fontSize={18}
          fontWeight={'bold'}
          color={'dimgray'}
        >
          Pro pokračování do aplikace je nejprve nutno se
          <Button variant={'text'} component={Link} to={RouteKeys.login}>přihlásit.</Button>
        </Typography>
      </Grid>
    </Grid>
  )
}
