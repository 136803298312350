
import React, { createContext, useState } from 'react'


interface ITopBarContext {
  setTitle: (title: string | undefined) => void
  title: string | undefined
  setBackLink: (backLink: string | undefined) => void
  backLink: string | undefined
}

export const TopBarContext = createContext<ITopBarContext>({
  setTitle: (title) => null,
  title: undefined,
  setBackLink: (backLink) => null,
  backLink: undefined
})

export function TopBarProvider ({ children }: { children: React.ReactElement }) {

  const [title, setTitle] = useState<string>()
  const [backLink, setBackLink] = useState<string>()

  return (
    <TopBarContext.Provider value={{
      setTitle,
      title,
      setBackLink,
      backLink
    }}>
      {children}
    </TopBarContext.Provider>
  )
}
