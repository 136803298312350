import React from 'react'
import { Grid, IconButton, Typography, useTheme } from '@mui/material'
import { useConfig } from '../providers/ConfigProvider'
import { Phone } from '@mui/icons-material'


export function DispetchingButton () {

  const config = useConfig()
  const theme = useTheme()

  return (
    <IconButton
      disableRipple
      sx={{
        'position': 'fixed',
        'bottom': 50,
        'right': 20,
        'backgroundColor': theme.palette.info.main,
        'borderRadius': '50%',
        'color': 'white',
        'width': 65,
        'height': 65,
        '&:hover': {
          background: theme.palette.info.main
        }
      }}
      component={'a'}
      href={`tel:${config.phoneToDispatch}`}
    >
      <Grid container direction={'column'}>
        <Grid item>
          <Phone/>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: 14 }}>
            DISP.
          </Typography>
        </Grid>
      </Grid>
    </IconButton>
  )
}
