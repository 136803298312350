import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { Theme } from '../theming/Theme'
import { AppRouter } from './router/AppRouter'
import { ConfigProvider } from '../providers/ConfigProvider'
import { SnackbarProvider } from 'notistack'
import { UserProvider } from '../providers/UserProvider'
import { ModalProvider } from './modal/ModalProvider'


export const App = () => {
  return (
    <ConfigProvider>
      <ModalProvider>
        <UserProvider>
          <ThemeProvider theme={Theme}>
            <SnackbarProvider>
              <AppRouter/>
            </SnackbarProvider>
          </ThemeProvider>
        </UserProvider>
      </ModalProvider>
    </ConfigProvider>
  )
}
