import * as React from 'react'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { type IModal, Modal } from './Modal'
import { Close } from '@mui/icons-material'
import { useContext } from 'react'
import { ModalContext } from './ModalProvider'


interface IConfirmModal extends Omit<IModal, 'title' | 'children' | 'content'> {
  title: JSX.Element
  content: JSX.Element
  confirmActionsArea?: (props: {
    closeModal: () => void
    confirmAction: (closeModal: () => void) => void
  }) => JSX.Element | null
  disableBaseActions?: boolean
  confirmAction: (closeModal: () => void) => void
}

export function ConfirmModal (props: IConfirmModal) {
  const modalCtx = useContext(ModalContext)
  return (
    <Modal name={props.name}>
      <Box sx={{
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 40px)'
      }}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sx={{ width: 'calc(100% - 60px)' }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>{props.title}</Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ width: '50px' }}>
              <IconButton
                aria-label="close"
                onClick={() => { modalCtx.closeModal(props.name) }}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            {props.content}
          </Box>
        </DialogContent>
        {props.confirmActionsArea?.({
          closeModal: () => { modalCtx.closeModal(props.name) },
          confirmAction: () => { props.confirmAction(() => { modalCtx.closeModal(props.name) }) }
        })}
        {!props.disableBaseActions &&
          <DialogActions>
            <Grid container justifyContent={'flex-end'} spacing={1}>
              <Grid item>
                <Button
                  color={'success'}
                  variant={'contained'}
                  onClick={() => {

                    props.confirmAction(() => { modalCtx.closeModal(props.name) })
                  }}
                >
                  Ano
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color={'error'}
                  variant={'contained'}
                  onClick={() => { modalCtx.closeModal(props.name) }}
                >
                  Ne
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        }
      </Box>
    </Modal>
  )

}
