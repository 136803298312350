import React, { useEffect } from 'react'
import { TopBar } from '../topbar/TopBar'
import { Box, Container } from '@mui/material'
import { white } from '../../theming/Theme'
import { Outlet, useLocation } from 'react-router-dom'
import { TopBarProvider } from '../topbar/TopBarProvider'
import { DispetchingButton } from '../DispetchingButton'


export const Layout = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <TopBarProvider>
      <Box sx={{ position: 'relative' }}>
        <TopBar/>
        <Container
          fixed
          sx={{
            minHeight: '100vh',
            backgroundColor: white,
            paddingTop: 7,
            paddingBottom: 4
          }}
        >
          <Box sx={{
            position: 'relative',
            flexGrow: 1,
            maxWidth: '100%',
            overflow: 'hidden'
          }}>
            <Outlet />
          </Box>
        </Container>
        <DispetchingButton/>
      </Box>
    </TopBarProvider>
  )
}
