import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { RequestType, useRequest } from '../../connections/fetch/DriverApiClient'
import { useConfig } from '../../providers/ConfigProvider'
import { grey } from '@mui/material/colors'
import { CollectionItem } from './components/CollectionItem'
import type { List } from '../../models/List'
import { type PaCollection } from '../../models/Collection'


export interface ICollectionData extends List<PaCollection> {}


export const CollectionsPage = () => {
  const config = useConfig()
  const [data, setData] = useState<ICollectionData>({
    items: [],
    totalCount: 0
  })

  const { executeRequest, loading } = useRequest<ICollectionData>({
    url: `${config.apiUrl}/api/collection/list`,
    body: JSON.stringify({}),
    type: RequestType.POST,
    onSuccess: (data) => {
      setData(data)
    }
  })


  const loadData = () => {
    executeRequest()
  }

  useEffect(() => {
    loadData()
  }, [])

  if (loading) {
    return (
      <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        minHeight={500}
      >
        <Grid item>
          <CircularProgress size={150}/>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      direction={'column'}
      minHeight={500}
      paddingTop={2}
      spacing={3}
    >
      {data.items.length === 0 &&
        <Grid item>
          <Box
            sx={{
              backgroundColor: grey['100'],
              padding: 2
            }}
          >
            <Grid container justifyContent={'center'} alignItems={'center'}>
              <Grid item>
                <Typography
                  variant={'body1'}
                >
                  Již nemáte žádný svoz
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      }
      {data.items.map((value, index) => (
        <CollectionItem data={value} key={index}/>
      ))}
    </Grid>
  )
}
