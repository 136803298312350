import { Button, Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useContext } from 'react'
import { type ButtonProps } from '@mui/material/Button/Button'
import { PackageContext } from './PackageContext'


export function NavigationButton (props: ButtonProps) {
  const { paPackage } = useContext(PackageContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!paPackage) {
    return null
  }

  function getAddress (): string {
    if (!paPackage) {
      return ''
    }
    return `${paPackage.address.street}, ${paPackage.address.city}, ${paPackage.address.zip}`
  }

  return (
    <>
      <Button fullWidth variant={'contained'} size={'large'} color={'info'} onClick={handleClick} {...props}>
        Navigovat
      </Button>
      <Menu
        PaperProps={{
          sx: {
            minWidth: 200
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={() => {
          handleClose()
          window.open(`waze://?q=${getAddress()}&navigate=yes`)
        }}>
          <Typography variant="inherit">WAZE</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          window.open(`https://maps.google.com/?q=${getAddress()}`)
        }}>
          <Typography variant="inherit">GOOGLE</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          window.open(`https://mapy.cz/zakladni?q=${getAddress()}`)
        }}>
          <Typography variant="inherit">MAPY.CZ</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
