import { type OptionsObject, type SnackbarMessage } from 'notistack'


class NotificationServiceObject {

  private snackBar: ((message: SnackbarMessage, options?: OptionsObject) => OptionsObject['key']) | null = null

  setSnackBar (snackBar: (message: SnackbarMessage, options?: OptionsObject) => OptionsObject['key']) {
    this.snackBar = snackBar
  }

  private truncateMessage (message: string, maxLength: number) {
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + '...'
    }
    return message
  }

  createNotification (
    message: string,
    options?: OptionsObject
  ) {
    options = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      variant: 'error',
      ...options
    }

    this.snackBar?.(this.truncateMessage(message, 500), options)
  }
}

export const NotificationService = new NotificationServiceObject()
