import { createTheme } from '@mui/material/styles'


export const white = '#FFFFFF'

export const Theme = createTheme({
  palette: {
    primary: {
      contrastText: white,
      main: '#de347e'
    },
    secondary: {
      contrastText: '#de347e',
      main: '#fcebee',
      dark: '#ffe1e7'
    }
  }
})
