import React from 'react'
import { Button, ListItemIcon, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Timer } from '@mui/icons-material'


interface ISelectDelay {
  onSelect: (minutes: number) => void
  disabled?: boolean
}

export function SelectDelay (props: ISelectDelay) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        disabled={props.disabled}
        onClick={handleClick}
        variant={'contained'}
        color={'info'}
      >
        Zpoždění zásilek
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {[15, 30, 45].map((minutes, key) => (
          <MenuItem key={key} onClick={() => {
            handleClose()
            props.onSelect(minutes)
          }}>
            <ListItemIcon>
              <Timer fontSize={'small'}/>
            </ListItemIcon>
            <Typography variant="inherit">{minutes} minut</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>

  )
}
