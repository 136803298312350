import { paymentMethod } from '../models/PaPackage'


export function payment (methodName: string): string {
  switch (methodName) {
    case paymentMethod.PAY_CASH_ON_DELIVERY:
      return 'Hotově při doručení'
    case paymentMethod.PAY_CARD_ON_DELIVERY:
      return 'Kartou při doručení'
    default:
      return methodName
  }
}
