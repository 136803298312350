export const STATUS_NEW = 1
export const STATUS_DELIVERING = 2
export const STATUS_DELIVERED = 3
export const STATUS_DEFERED = 4
export const STATUS_NOT_DELIVERED = 5
export const STATUS_ORDER_HAS_NO_PACKAGE = 7
export const STATUS_NEARBY = 6


export const paymentMethod = {
  PAY_CARD_ON_DELIVERY: 'PAY_CARD_ON_DELIVERY',
  PAY_CASH_ON_DELIVERY: 'PAY_CASH_ON_DELIVERY'
}

export interface PaPackage {
  id: number
  position: number
  orderNumber: number
  orderId: number
  expectedAt?: string
  deliveredAt?: string
  status: number
  recepientName: string
  phone: string
  notes: string[]
  packageCodesCount: number
  paymentMethod: string
  orderPrice: number
  isPayed: boolean
  boxId?: number
  address: {
    city: string
    street: string
    zip: string
  }
}
