import { createBrowserRouter } from 'react-router-dom'
import { Layout } from '../layouts/Layout'
import { NotFoundPage } from '../../pages/NotFoundPage'
import { HomePage } from '../../pages/HomePage'
import { LoginPage } from '../../pages/LoginPage'
import { CollectionsPage } from '../../pages/Collections/CollectionsPage'
import { LoggedUser } from '../AccessComponent'
import { CollectionDetailPage } from '../../pages/Collections/CollectionDetailPage'
import { PackageDetailPage } from '../../pages/Packages/PackageDetailPage'


export const RouteKeys = {
  basePath: '/',
  collections: '/collections',
  collectionsDetail: '/collections/:id',
  packageDetail: '/collections/:id/package/:packageId',
  login: '/login'
}

export function createLink (routeKey: string, args: Record<string, string | number>) {
  let link = routeKey
  for (const key in args) {
    link = link.replace(`:${key}`, String(args[key]))
  }
  return link
}


export const Routes = createBrowserRouter([
  {
    path: RouteKeys.basePath,
    Component: Layout,
    children: [
      {
        path: RouteKeys.basePath,
        Component: HomePage
      },
      {
        path: RouteKeys.collections,
        Component: LoggedUser(CollectionsPage)
      },
      {
        path: RouteKeys.collectionsDetail,
        Component: LoggedUser(CollectionDetailPage)
      },
      {
        path: RouteKeys.packageDetail,
        Component: LoggedUser(PackageDetailPage)
      },
      {
        path: RouteKeys.login,
        Component: LoginPage
      }
    ]
  },
  {
    path: '*',
    Component: NotFoundPage
  }
])
