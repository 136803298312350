import React, { useContext } from 'react'
import { AppBar, Box, Button, Container, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Ico from '../../assets/images/symbol-gradient.png'
import { RouteKeys } from '../router/Routes'
import { UserContext } from '../../providers/UserProvider'
import { LoggedUser } from './LoggedUser'
import { TopBarContext } from './TopBarProvider'
import { ArrowBackIosNew } from '@mui/icons-material'


export const TopBar = () => {

  const userContext = useContext(UserContext)
  const { backLink, title } = useContext(TopBarContext)
  return (
    <AppBar position={'fixed'} elevation={0} color={'secondary'}>
      <Container fixed>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            {backLink
              ? (
                <Grid container alignItems={'center'} spacing={0}>
                  <Grid item>
                    <IconButton
                      size={'large'}
                      sx={{
                        paddingLeft: 0
                      }}
                      component={Link}
                      to={backLink}
                    >
                      <ArrowBackIosNew color={'primary'}/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 17 }}>
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              )
              : <IconButton
                size={'large'}
                component={Link}
                to={RouteKeys.basePath}
              >
                <img
                  style={{
                    maxWidth: '30px',
                    height: 'auto'
                  }}
                  src={Ico}
                  alt={'plus'}
                />
              </IconButton>
            }
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {userContext.isAuthenticated
              ? <LoggedUser/>
              : <Button
                variant={'outlined'}
                color={'primary'}
                component={Link}
                to={RouteKeys.login}
              >
                Přihlásit se
              </Button>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
