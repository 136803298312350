import React from 'react'
import { Grid, Typography } from '@mui/material'
import { LoginForm } from '../components/login/LoginForm'


export const LoginPage = () => {
  return (
    <Grid
      paddingTop={5}
      container
      direction={'column'}
      alignItems={'center'}
      minHeight={250}
    >
      <Grid item>
        <Typography
          variant={'h3'}
          textAlign={'center'}
          color={'primary'}
          fontWeight={'bold'}
        >
          Přihlášení
        </Typography>
      </Grid>
      <Grid item>
        <LoginForm/>
      </Grid>
    </Grid>
  )
}
