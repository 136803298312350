import * as React from 'react'
import { useContext } from 'react'
import { Fade, Modal as MuiModal } from '@mui/material'
import { ModalContext } from './ModalProvider'
import { type ModalProps } from '@mui/material/Modal/Modal'


export interface IModal extends Omit<ModalProps, 'open'> {
  name: string
}

export function Modal (props: IModal) {
  const modalCtx = useContext(ModalContext)
  const open = modalCtx.isOpen(props.name)

  return (
    <MuiModal
      {...props}
      open={open}
    >
      <Fade in={open}>
        {props.children}
      </Fade>
    </MuiModal>
  )

}
