import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { Routes } from './Routes'
import { useSnackbar } from 'notistack'
import { NotificationService } from '../../services/Notification'


export const AppRouter = () => {
  const { enqueueSnackbar } = useSnackbar()
  NotificationService.setSnackBar(enqueueSnackbar)

  return (
    <RouterProvider router={Routes}/>
  )
}
