import React, { useContext } from 'react'
import { Alert, Box, Button, Chip, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'
import { createLink, RouteKeys } from '../../../components/router/Routes'
import { type PaCollection } from '../../../models/Collection'
import { useConfig } from '../../../providers/ConfigProvider'
import { ConfirmModal } from '../../../components/modal/ConfirmModal'
import { ModalContext } from '../../../components/modal/ModalProvider'
import moment from 'moment'
import { RequestType, useRequest } from '../../../connections/fetch/DriverApiClient'
import { NotificationService } from '../../../services/Notification'


interface ICollectionItem {
  data: PaCollection
}

export const CollectionItem = ({ data }: ICollectionItem) => {

  const config = useConfig()
  const navigate = useNavigate()
  const modalCtx = useContext(ModalContext)

  function isReady (): boolean {
    return data.sms > 0
  }

  const startDeliveringRequest = useRequest<{ message: string, error: string }>({
    url: `${config.apiUrl}/api/collection/start-delivering/${data.id}`,
    type: RequestType.POST,
    body: JSON.stringify({}),
    onSuccess: (responseData) => {
      if (responseData.message) {
        NotificationService.createNotification(responseData.message, {
          variant: 'success'
        })
        window.open(`pilulka-gps://locator?id=${data.pa_car.id}&locale=${config.countryCode}`, '_blank')
        window.focus()
        navigate(createLink(RouteKeys.collectionsDetail, { id: data.id }))
      }
      if (responseData.error) {
        NotificationService.createNotification(responseData.error, {
          variant: 'error'
        })
      }
    }
  })

  return (
    <Grid item>
      <Box
        sx={{
          backgroundColor: grey['100'],
          padding: 2
        }}
      >
        <Grid container direction={'column'} spacing={2}>
          <Grid item container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <Typography
                variant={'body2'}
              >
                Svoz: {data.pa_slot?.since ? moment(data.pa_slot?.since).format('DD.MM.') : ''} slot:{data.pa_slot?.slot_number ? data.pa_slot?.slot_number : ''}
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                sx={{
                  borderRadius: 0
                }}
                color={isReady() ? 'primary' : 'secondary'}
                label={isReady() ? 'Připraveno' : 'Nepřipraveno'}
              />
            </Grid>
          </Grid>
          <Grid item container direction={'column'}>
            <Grid item>
              <Typography
                sx={{ fontSize: 14 }}
              >
                Auto
              </Typography>
            </Grid>
            <Grid item container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <Typography>
                  {data.pa_car.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data.pa_car.spz}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Grid container direction={'column'} spacing={1}>
                <Grid item>
                  <Typography>
                    Počet objednávek
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {data.count_packages}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction={'column'} spacing={1}>
                <Grid item>
                  <Typography>
                    Počet balíků
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {data.labels_count}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {isReady()
        ? <Grid item container direction={'column'}>
          <Grid item>
            <Button
              sx={{
                borderRadius: 0
              }}
              variant={'contained'}
              fullWidth
              onClick={(e) => {
                modalCtx.openModal('startCollection')
              }}
            >
              Zahájit svoz
            </Button>
            <ConfirmModal
              name={'startCollection'}
              title={<Typography>Zapněte GPS aplikace!!!</Typography>}
              content={<Typography>Opravdu chcete zahájit svoz <b>{data.id}</b>?</Typography>}
              confirmAction={(closeModal) => {
                startDeliveringRequest.executeRequest()
                closeModal()
              }}
            />
          </Grid>
        </Grid>
        : <Grid item container direction={'column'}>
          <Alert severity={'error'}>
            Neposlané SMS, rozvoz nelze začít. Kontaktujte dispečera.
          </Alert>
        </Grid>
      }
    </Grid>
  )
}
