import { useConfig } from '../providers/ConfigProvider'


export function useCurrency () {
  const config = useConfig()
  const formatter = new Intl.NumberFormat(config.locale, {
    style: 'currency',
    currency: config.currency
  })

  function format (value: number) {
    return formatter.format(value)
  }

  return { format }

}
