import { useContext, useEffect } from 'react'
import { UserContext } from '../providers/UserProvider'
import { useNavigate } from 'react-router-dom'
import { RouteKeys } from './router/Routes'
import * as React from 'react'


interface IAccessComponent {
  Component: React.ComponentType
}

const AccessComponent = ({ Component }: IAccessComponent) => {
  const userContext = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!userContext.isAuthenticated) {
      navigate(RouteKeys.login)
    }
  }, [userContext])

  if (!userContext.isAuthenticated) {
    return null
  }
  return <Component/>
}

export const LoggedUser = (Component: React.ComponentType) => {
  // eslint-disable-next-line react/display-name
  return () => <AccessComponent Component={Component}/>
}
