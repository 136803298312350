import { Button } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import { type SmsTemplate } from '../../../models/SmsTemplate'


interface ISelectTemplate {
  templates: SmsTemplate[]
  onSelect: (key: string) => void
}

export function SelectSMSTemplate (props: ISelectTemplate) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (props.templates.length === 0) {
    return null
  }

  return (
    <>
      <Button variant={'contained'} onClick={handleClick}>
        Výběr z SMS šablon
      </Button>
      <Menu
        PaperProps={{
          sx: {
            minWidth: 200
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {props.templates.map((template, index) => {
          return (
            <MenuItem
              onClick={() => {
                props.onSelect(template.text)
                handleClose()
              }}
              sx={{ whiteSpace: 'unset', wordBreak: 'break-all' }}
              key={index}
            >
              {template.title}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )

}
