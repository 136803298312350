import React, { createContext, useContext, useEffect, useState } from 'react'
import { Box, Button, Checkbox, CircularProgress, colors, Grid, Typography } from '@mui/material'
import { RequestType, useRequest } from '../../connections/fetch/DriverApiClient'
import { useConfig } from '../../providers/ConfigProvider'
import { Link, useParams } from 'react-router-dom'
import { TopBarContext } from '../../components/topbar/TopBarProvider'
import { createLink, RouteKeys } from '../../components/router/Routes'
import { type PaPackage, STATUS_DEFERED, STATUS_DELIVERING } from '../../models/PaPackage'
import { type List } from '../../models/List'
import { Package } from '../Packages/components/Package'
import { SelectDelay } from './components/SelectDelay'
import { NotificationService } from '../../services/Notification'


interface ICollectionDetailContext {
  checkedPackages: number[]
  addChecked: (packageId: number) => void
  removeChecked: (packageId: number) => void
  isChecked: (packageId: number) => boolean
}

export const CollectionDetailContext = createContext<ICollectionDetailContext>({
  addChecked: (packageId: number) => null,
  removeChecked: (packageId: number) => null,
  isChecked: (packageId: number) => false,
  checkedPackages: []
})

export interface PaPackageCollection extends List<PaPackage> {}

export const CollectionDetailPage = () => {
  const config = useConfig()
  const { id } = useParams()
  const { setBackLink, setTitle } = useContext(TopBarContext)
  const [checkedPackages, setCheckedPackages] = useState<number[]>([])

  useEffect(() => {
    setTitle(`Svoz ${id ?? ''}`)
    setBackLink(RouteKeys.collections)
    return () => {
      setBackLink(undefined)
      setTitle(undefined)
    }
  }, [])

  const [paPackages, setPaPackages] = useState<PaPackage[]>([])
  const [paDeferedPackages, setPaDeferedPackages] = useState<PaPackage[]>([])

  const listDeliveringPaPackages = useRequest<PaPackageCollection>({
    url: `${config.apiUrl}/api/pa_package/list`,
    type: RequestType.POST,
    body: JSON.stringify({
      pa_collection_id: id,
      statuses: [STATUS_DELIVERING]
    }),
    onSuccess: (data) => {
      const items = data.items
      setPaPackages(items)
      setCheckedPackages(items.filter(item => item.status === STATUS_DELIVERING).map(item => item.id))
    }
  })

  const listDefered = useRequest<PaPackageCollection>({
    url: `${config.apiUrl}/api/pa_package/list`,
    type: RequestType.POST,
    body: JSON.stringify({
      pa_collection_id: id,
      statuses: [STATUS_DEFERED]
    }),
    onSuccess: (data) => {
      const items = data.items
      setPaDeferedPackages(items)
    }
  })

  const sendDelaySmsRequest = useRequest<{ message: string, error: string }>({
    url: `${config.apiUrl}/api/pa_package/send-delay-sms`,
    type: RequestType.POST,
    onSuccess: (data) => {
      if (data.message) {
        NotificationService.createNotification(data.message, {
          variant: 'success'
        })
      }
      if (data.error) {
        NotificationService.createNotification(data.error, {
          variant: 'error'
        })
      }
    }
  })

  const loadData = () => {
    listDeliveringPaPackages.executeRequest()
    listDefered.executeRequest()
  }

  useEffect(() => {
    if (!id) {
      return
    }
    loadData()
  }, [id])

  if (listDefered.loading || listDeliveringPaPackages.loading) {
    return (
      <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        minHeight={500}
      >
        <Grid item>
          <CircularProgress size={150}/>
        </Grid>
      </Grid>
    )
  }

  if (paPackages.length === 0 && paDeferedPackages.length === 0) {
    return (
      <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        minHeight={500}
        spacing={1}
      >
        <Grid item>
          <Typography
            variant={'h6'}
            textAlign={'center'}
          >
            Tento svoz nemá žádné Balíky
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant={'outlined'}
            component={Link}
            to={createLink(RouteKeys.collections, {})}
          >
            Zpět
          </Button>
        </Grid>
      </Grid>
    )
  }

  function addChecked (packageId: number) {
    if (!checkedPackages.includes(packageId)) {
      const newPackages = [...checkedPackages]
      newPackages.push(packageId)
      setCheckedPackages(newPackages)
    }
  }
  function removeChecked (packageId: number) {
    const index = checkedPackages.indexOf(packageId)
    if (index !== -1) {
      const newPackages = [...checkedPackages]
      newPackages.splice(index, 1)
      setCheckedPackages(newPackages)
    }
  }

  function isChecked (packageId: number) {
    return checkedPackages.includes(packageId)
  }
  const deliveringPackages = paPackages.filter(item => item.status === STATUS_DELIVERING)
  const isCheckedAll = checkedPackages.length === deliveringPackages.length

  return (
    <CollectionDetailContext.Provider value={{
      checkedPackages,
      addChecked,
      removeChecked,
      isChecked
    }}>
      <Grid
        container
        direction={'column'}
        minHeight={500}
        paddingTop={2}
        spacing={1}
      >
        {deliveringPackages.length > 0 &&
          <Grid item>
            <Box sx={{ backgroundColor: colors.grey[300], p: 1, pr: 0 }}>
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <SelectDelay disabled={checkedPackages.length === 0} onSelect={(minutes) => {
                    if (checkedPackages.length === 0) {
                      return
                    }
                    sendDelaySmsRequest.executeRequest({
                      body: JSON.stringify({
                        collectionId: id,
                        ids: checkedPackages,
                        minutes
                      })
                    })
                  }}/>
                </Grid>
                <Grid item>
                  <Checkbox
                    checked={isCheckedAll}
                    onClick={() => {
                      if (isCheckedAll) {
                        setCheckedPackages([])
                        return
                      }
                      const paPackagesIds = deliveringPackages.map((item) => item.id)
                      setCheckedPackages(paPackagesIds)
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        }
        {paPackages.map((value, index) => (
          <Grid key={index} item>
            <Package
              collectionId={String(id)}
              paPackage={value}
            />
          </Grid>
        ))}
        {paDeferedPackages.length > 0 &&
          <Grid item marginTop={5}>
            <Typography>
              Odložené zásilky:
            </Typography>
          </Grid>
        }
        {paDeferedPackages.map((value, index) => (
          <Grid key={index} item>
            <Package
              collectionId={String(id)}
              paPackage={value}
            />
          </Grid>
        ))}
      </Grid>
    </CollectionDetailContext.Provider>
  )
}
