import React, { useContext } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { PackageContext } from './PackageContext'


export function PackageDescriptions () {
  const { paPackage } = useContext(PackageContext)
  if (!paPackage) {
    return null
  }
  return (
    <Grid container direction={'column'} spacing={0}>
      <Grid item>
        <Typography variant={'caption'}>
          Poznámky:
        </Typography>
      </Grid>
      <Grid item container>
        <Grid container direction={'column'} spacing={1}>
          {paPackage.notes.map((value, index) => (
            <Grid item key={index}>
              <Box sx={{ backgroundColor: '#EFEFEF', padding: 2 }}>
                {value}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
