import { Button } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'


interface ISelectTemplate {
  templates: Record<string, string>
  onSelect: (key: string) => void
}

export function SelectTemplate (props: ISelectTemplate) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button variant={'contained'} onClick={handleClick}>
        Výběr z šablony
      </Button>
      <Menu
        PaperProps={{
          sx: {
            minWidth: 200
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {Object.keys(props.templates).map((key, index) => {
          return (
            <MenuItem
              onClick={() => {
                props.onSelect(key)
                handleClose()
              }}
              sx={{ whiteSpace: 'unset', wordBreak: 'break-all' }}
              key={index}
              value={String(key)}
            >
              {props.templates[key]}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )

}
