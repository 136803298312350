import React, { createContext, useContext, useEffect, useState } from 'react'


interface IConfig {
  locale: string
  countryCode: string
  currency: string
  apiUrl: string
  phoneToDispatch: string
  boUrl: string
}

export const ConfigContext = createContext<IConfig>({
  locale: '',
  countryCode: '',
  currency: '',
  apiUrl: '',
  phoneToDispatch: '',
  boUrl: ''
})

export const useConfig = (): IConfig => {
  return useContext(ConfigContext)
}

interface IConfigProvider {
  children: React.ReactNode
}

const initialValues: IConfig = {
  locale: 'cs-CZ',
  countryCode: 'cz',
  currency: 'CZK',
  apiUrl: '',
  phoneToDispatch: '',
  boUrl: ''
}

export const ConfigProvider = (props: IConfigProvider) => {

  const [config, setConfig] = useState<IConfig>()

  const loadConfig = async (): Promise<void> => {
    const configLocale = await loadFile('/config.locale.json')
    const config = await loadFile('/config.json')

    setConfig({
      ...config,
      ...configLocale
    })
  }

  const loadFile = async (filePath: string): Promise<IConfig> => {
    const isDevel = process.env.NODE_ENV === 'test'
    if (isDevel) {
      return initialValues
    }

    return await fetch(filePath)
      .then(async (response) => {
        return await response.json()
      }).catch(e => {
        console.error('Download config file failed', filePath, e.message)
      })
      .then((json) => {
        return json
      })
  }

  useEffect(() => {
    loadConfig()
  }, [])

  if (config == null) {
    return null
  }

  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  )
}
