import type { PaPackage } from '../../../models/PaPackage'
import { createContext } from 'react'


interface IPackageContext {
  paPackage: PaPackage | null
  collectionId: string
}

export const PackageContext = createContext<IPackageContext>({
  collectionId: '',
  paPackage: null
})
