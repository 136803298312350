import HttpApiCallError from './HttpApiCallError'


export const asyncFetch = async (url: string, requestConfig: RequestInit = {}): Promise<any> => {
  let response: Response
  try {
    response = await fetch(url, requestConfig)
  } catch (e) {
    const error = e as Error
    const httpError = new HttpApiCallError(
      `Failed to Fetch: ${url}`,
      500
    )
    httpError.textResponse = error.message
    throw httpError

  }
  const isSuccess = response.status >= 200 && response.status < 300
  const contentType = response.headers.get('content-type')
  if (isSuccess) {
    if (contentType?.includes('application/json')) {
      try {
        return await response.json()
      } catch (e) {
        return {}
      }
    } else {
      try {
        return await response.text()
      } catch (e) {
        return ''
      }
    }
  }

  let jsonResponse
  let textResponse

  const isJsonResponse = contentType?.includes('application/json')

  if (isJsonResponse) {
    jsonResponse = await response.json()
  } else {
    textResponse = await response.text()
  }

  let errorMessage = response.statusText
  if (!errorMessage) {
    if (isJsonResponse && jsonResponse.message) {
      errorMessage = jsonResponse.message
    }
    if (!isJsonResponse && textResponse) {
      errorMessage = textResponse
    }
  }

  const error = new HttpApiCallError(
    errorMessage,
    response.status
  )
  error.jsonResponse = jsonResponse
  error.textResponse = textResponse

  throw error
}
