import { Box, Chip, Grid, Typography, useTheme } from '@mui/material'
import { paymentMethod, STATUS_DELIVERED } from '../../../models/PaPackage'
import moment from 'moment/moment'
import React, { useContext } from 'react'
import { Info } from '@mui/icons-material'
import { useCurrency } from '../../../helpers/currency'
import { phoneFormat } from '../../../helpers/phone'
import { PackageContext } from './PackageContext'


export function PackageDetail () {
  const currencyFormatter = useCurrency()
  const { paPackage } = useContext(PackageContext)
  const theme = useTheme()
  if (!paPackage) {
    return null
  }

  function getPaymentMethod (): string {
    if (!paPackage) {
      return ''
    }

    switch (paPackage.paymentMethod) {
      case paymentMethod.PAY_CARD_ON_DELIVERY:
        return 'Kartou při doručení'
      case paymentMethod.PAY_CASH_ON_DELIVERY:
        return 'Hotovostí při doručení'
      default:
        return paPackage.paymentMethod
    }

  }

  return (
    <Box sx={{
      backgroundColor: '#e5e5e5',
      minHeight: 50,
      position: 'relative'
    }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#D1D1D1',
          padding: 0.5,
          paddingLeft: 1,
          paddingRight: 1
        }}>
        {paPackage.position}
      </Box>
      <Grid container direction={'column'} paddingTop={3}>
        <Grid container spacing={3} sx={{ padding: 2 }} wrap={'nowrap'}>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid container direction={'column'} spacing={1} alignItems={'center'}>
              <Grid item>
                <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  {paPackage.expectedAt ? moment(paPackage.expectedAt).format('HH:mm') : 'N/A'}
                </Typography>
              </Grid>
              {paPackage.packageCodesCount > 0 &&
                <Grid item container direction={'column'} alignItems={'center'}>
                  <Grid item>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
                      {paPackage.packageCodesCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    Balík
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item zeroMinWidth>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <Typography>
                  {paPackage.recepientName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ overflowWrap: 'break-word' }}>
                  {paPackage.address.street}<br/>
                  {paPackage.address.city}, {paPackage.address.zip}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {phoneFormat(paPackage.phone)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent={'flex-end'} spacing={1} padding={1}>
          {paPackage.status === STATUS_DELIVERED &&
            <Grid item>
              <Chip color={'success'} label={'Doručeno'} sx={{ borderRadius: 'unset' }} />
            </Grid>
          }
          {paPackage.isPayed &&
            <Grid item>
              <Chip color={'success'} label={'Zaplaceno'} sx={{ borderRadius: 'unset' }} />
            </Grid>
          }
        </Grid>
        {!paPackage.isPayed &&
          <Grid item padding={1}>
            <Box
              sx={{
                padding: 1,
                backgroundColor: theme.palette.error.main,
                color: 'white'
              }}
            >
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                      <Info fontSize={'small'}/>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: 12
                        }}
                      >
                        {getPaymentMethod()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 'bold'
                    }}
                  >
                    {currencyFormatter.format(paPackage.orderPrice)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        }
      </Grid>
    </Box>
  )
}
