import React, { useContext } from 'react'
import { Avatar, IconButton, ListItemIcon, Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { UserContext } from '../../providers/UserProvider'
import { Logout } from '@mui/icons-material'


export const LoggedUser = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const userContext = useContext(UserContext)

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar sx={{ }} >
          {userContext.loggedUser?.username?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={() => {
          handleClose()
          userContext.logout()
        }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Odhlásit se</Typography>
        </MenuItem>
      </Menu>
    </>
  )

}
