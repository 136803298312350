import React, { createContext, useState } from 'react'
import { jwtDecode } from 'jwt-decode'


interface IUserContext {
  login: (token: string) => void
  logout: () => void
  loggedUser?: IData
  isAuthenticated: boolean
}

export const UserContext = createContext<IUserContext>({
  login: (token) => {},
  logout: () => {},
  isAuthenticated: false
})

interface IData {
  roles: string[]
  isAuthenticated: boolean
  token?: string
  username?: string
}

export const localStorageUserKey = 'user'

export const UserProvider = ({ children }: { children: JSX.Element }) => {

  const initialData: IData = {
    roles: [],
    isAuthenticated: false
  }

  const getDataFromLocalStorage = (): IData => {
    const localStorageData = localStorage.getItem(localStorageUserKey)
    if (localStorageData) {
      const newData = JSON.parse(localStorageData)
      return {
        username: newData.username,
        roles: newData.roles,
        token: newData.token,
        isAuthenticated: newData.isAuthenticated
      }
    }
    return initialData
  }

  const [data, setData] = useState<IData>(getDataFromLocalStorage())

  const login = (token: string) => {
    const { roles, username } = jwtDecode<{ roles: string[], username: string }>(token)
    const newData: IData = {
      isAuthenticated: true,
      username,
      roles,
      token
    }
    setData(newData)
    localStorage.setItem(localStorageUserKey, JSON.stringify(newData))
  }

  const logout = () => {
    setData(initialData)
    localStorage.setItem(localStorageUserKey, JSON.stringify(initialData))
  }


  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        loggedUser: data,
        isAuthenticated: data.isAuthenticated
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
