import { useConfig } from '../../providers/ConfigProvider'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { TopBarContext } from '../../components/topbar/TopBarProvider'
import { createLink, RouteKeys } from '../../components/router/Routes'
import { RequestType, useRequest } from '../../connections/fetch/DriverApiClient'
import { type PaPackage, paymentMethod } from '../../models/PaPackage'
import { Button, CircularProgress, colors, FormControl, Grid, TextField, Typography } from '@mui/material'
import { PackageDetail } from './components/PackageDetail'
import { PackageDescriptions } from './components/PackageDescriptions'
import { NavigationButton } from './components/NavigationButton'
import { ConfirmModal } from '../../components/modal/ConfirmModal'
import { ModalContext } from '../../components/modal/ModalProvider'
import { NotificationService } from '../../services/Notification'
import { PackageContext } from './components/PackageContext'
import { SelectTemplate } from './components/SelectTemplate'
import { useCurrency } from '../../helpers/currency'
import { payment } from '../../helpers/payments'
import { SelectSMSTemplate } from './components/SelectSMSTemplate'
import { type SmsTemplate } from '../../models/SmsTemplate'


export function PackageDetailPage () {
  const config = useConfig()
  const { id, packageId } = useParams()
  const { setBackLink, setTitle } = useContext(TopBarContext)
  const [detail, setDetail] = useState<PaPackage>()
  const [notes, setNotes] = useState<Record<string, string>>({})
  const [smsTemplates, setSmsTemplates] = useState<SmsTemplate[]>([])
  const [selectedNote, setSelectedNote] = useState<string>()
  const [finalNote, setFinalNote] = useState<string>('')
  const [finalSMS, setFinalSMS] = useState<string>('')
  const modalCtx = useContext(ModalContext)
  const navigate = useNavigate()
  const currencyFormatter = useCurrency()
  const detailRequest = useRequest<PaPackage>({
    url: `${config.apiUrl}/api/pa_package/detail/${String(packageId)}`,
    type: RequestType.GET,
    onSuccess: (data) => {
      setDetail(data)
    }
  })

  const sendToTerminalRequest = useRequest<{ message: string, error: string }>({
    url: `${config.apiUrl}/api/pa_package/send-to-terminal`,
    type: RequestType.POST,
    body: JSON.stringify({
      packageId
    }),
    onSuccess: (data) => {
      if (data.message) {
        NotificationService.createNotification(data.message, {
          variant: 'success'
        })
      }
      if (data.error) {
        NotificationService.createNotification(data.error, {
          variant: 'error'
        })
      }
    }
  })

  const loadNotes = useRequest<Record<string, string>>({
    url: `${config.apiUrl}/api/pa_package/list-notes`,
    type: RequestType.GET,
    onSuccess: (data) => {
      setNotes(data)
    }
  })

  const loadSmsTemplates = useRequest<{ message: SmsTemplate[] }>({
    url: `${config.apiUrl}/api/sms-template/list`,
    type: RequestType.GET,
    onSuccess: (data) => {
      setSmsTemplates(data.message)
    }
  })

  const smsTemplateSendRequest = useRequest<{ message: string, error: string }>({
    url: `${config.apiUrl}/api/sms-template/send`,
    type: RequestType.POST,
    body: JSON.stringify({
      text: finalSMS,
      packageId
    }),
    onSuccess: (data) => {
      if (data.message) {
        NotificationService.createNotification(data.message, {
          variant: 'success'
        })
      }
      if (data.error) {
        NotificationService.createNotification(data.error, {
          variant: 'error'
        })
      }
    }
  })

  const setDeliveredRequest = useRequest<{ message: string, error: string }>({
    url: `${config.apiUrl}/api/pa_package/delivered`,
    type: RequestType.POST,
    body: JSON.stringify({
      paCollectionId: id,
      packageId
    }),
    onSuccess: (data) => {
      if (data.message) {
        navigate(createLink(RouteKeys.collectionsDetail, { id: String(id) }))
        NotificationService.createNotification(data.message, {
          variant: 'success'
        })
      }
      if (data.error) {
        NotificationService.createNotification(data.error, {
          variant: 'error'
        })
      }
    }
  })

  const setNotDeliveredRequest = useRequest<{ message: string, error: string }>({
    url: `${config.apiUrl}/api/pa_package/not-delivered`,
    type: RequestType.POST,
    body: JSON.stringify({
      packageId,
      note: finalNote
    }),
    onSuccess: (data) => {
      if (data.message) {
        navigate(createLink(RouteKeys.collectionsDetail, { id: String(id) }))
        NotificationService.createNotification(data.message, {
          variant: 'success'
        })
      }
      if (data.error) {
        NotificationService.createNotification(data.error, {
          variant: 'error'
        })
      }
    }
  })

  const setDeferredSMSRequest = useRequest<{ message: string, error: string }>({
    url: `${config.apiUrl}/api/pa_package/deferred-sms`,
    type: RequestType.POST,
    body: JSON.stringify({
      packageId
    }),
    onSuccess: (data) => {
      if (data.message) {
        navigate(createLink(RouteKeys.collectionsDetail, { id: String(id) }))
        NotificationService.createNotification(data.message, {
          variant: 'success'
        })
      }
      if (data.error) {
        NotificationService.createNotification(data.error, {
          variant: 'error'
        })
      }
    }
  })

  useEffect(() => {
    if (typeof selectedNote === 'undefined' || !notes[selectedNote]) {
      setFinalNote('')
      return
    }
    setFinalNote(String(notes[selectedNote]))
  }, [selectedNote])

  useEffect(() => {
    detailRequest.executeRequest()
    loadSmsTemplates.executeRequest()
    loadNotes.executeRequest()
  }, [])

  useEffect(() => {
    setTitle(`Objednávka: ${detail?.orderNumber ?? ''}`)
    setBackLink(createLink(RouteKeys.collectionsDetail, { id: String(id) }))
    return () => {
      setBackLink(undefined)
      setTitle(undefined)
    }
  }, [detail])

  if (detailRequest.loading) {
    return (
      <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        minHeight={500}
      >
        <Grid item>
          <CircularProgress size={150}/>
        </Grid>
      </Grid>
    )
  }

  function getBoxUrl (): string {
    if (!detail?.boxId) {
      return ''
    }
    let baseBackofficeUrl = 'https://ipilulka.cz'
    if (config.locale === 'sk') {
      baseBackofficeUrl = 'https://new.ipilulka.sk'
    }
    return `${baseBackofficeUrl}/car/pilulka-box-order-preview/?boxId=${detail?.boxId}&collectionId=${String(id)}&packageId=${detail.id}`
  }

  if (!detail) {
    return null
  }

  return (
    <PackageContext.Provider value={{
      paPackage: detail,
      collectionId: String(id)
    }}>
      <Grid container direction={'column'} spacing={1} paddingTop={3}>
        <Grid item>
          <PackageDetail/>
        </Grid>
        {detail.notes.length > 0 &&
          <Grid item>
            <PackageDescriptions/>
          </Grid>
        }
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <Button
              disabled={!detail.phone}
              fullWidth
              variant={'contained'}
              size={'large'}
              color={'info'}
              component={'a'}
              href={`tel:${detail.phone}`}
            >
              Zavolat
            </Button>
          </Grid>
          {detail.boxId &&
            <Grid item xs={6}>
              <Button
                fullWidth
                variant={'contained'}
                size={'large'}
                color={'info'}
                component={'a'}
                href={getBoxUrl()}
                target={'_blank'}
              >
                Pilulka BOX blocks
              </Button>
            </Grid>
          }
          <Grid item xs={6}>
            <NavigationButton/>
          </Grid>
          {detail.paymentMethod === paymentMethod.PAY_CARD_ON_DELIVERY && !detail.isPayed &&
            <Grid item xs={12}>
              <Button
                onClick={() => { sendToTerminalRequest.executeRequest() }}
                fullWidth
                variant={'contained'}
                size={'large'}
                color={'inherit'}
                sx={{
                  textAlign: 'center'
                }}
              >
                Odeslat do terminálu
              </Button>
            </Grid>
          }
        </Grid>
        <Grid item container spacing={1} marginTop={1}>
          <Grid item xs={6}>
            <ConfirmModal
              name={'deliveredConfirm'}
              title={<Typography>Doručení zásilky</Typography>}
              content={<Typography>Opravdu chcete zásilku označit jako <b>doručenou</b>?</Typography>}
              confirmAction={(closeModal) => {
                setDeliveredRequest.executeRequest()
                closeModal()
              }}
            />
            <ConfirmModal
              name={'deliveredConfirmCOD'}
              title={<Typography>Doručení zásilky</Typography>}
              content={
                <Grid container direction={'column'} spacing={2} sx={{
                  backgroundColor: colors.grey['600'],
                  alignItems: 'center',
                  padding: 5
                }}>
                  <Grid item>
                    <Typography sx={{ color: 'white' }}>Platba <b>{payment(detail.paymentMethod)}</b></Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: 'white', fontSize: 30, fontWeight: 'bold' }}>{currencyFormatter.format(detail.orderPrice)}</Typography>
                  </Grid>
                </Grid>
              }
              confirmActionsArea={(confirmProps) => (
                <Grid container direction={'row'} sx={{
                  justifyContent: 'space-between',
                  padding: 5
                }}>
                  <Grid item>
                    <Button variant={'contained'} color={'success'} onClick={() => {
                      confirmProps.confirmAction(confirmProps.closeModal)
                    }}>
                      ZAPLACENO
                    </Button>
                  </Grid>
                  <Button variant={'contained'} color={'error'} onClick={() => { confirmProps.closeModal() }}>
                    NEZAPLACENO
                  </Button>
                </Grid>
              )}
              disableBaseActions={true}
              confirmAction={(closeModal) => {
                setDeliveredRequest.executeRequest()
                closeModal()
              }}
            />
            <Button fullWidth variant={'contained'} size={'large'} color={'success'} onClick={() => {
              if ([paymentMethod.PAY_CARD_ON_DELIVERY, paymentMethod.PAY_CASH_ON_DELIVERY].includes(detail.paymentMethod)) {
                modalCtx.openModal('deliveredConfirmCOD')
                return
              }
              modalCtx.openModal('deliveredConfirm')
            }}>
              DORUČENO
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ConfirmModal
              name={'notDeliveredConfirm'}
              title={<Typography>Nedoručení zásilky</Typography>}
              content={(
                <Grid container direction={'column'} spacing={1}>
                  <Grid item>
                    <Typography>Opravdu chcete zásilku označit jako <b>nedoručenou</b>?</Typography>
                  </Grid>
                  <Grid item>
                    <SelectTemplate templates={notes} onSelect={(key) => { setSelectedNote(key) }}/>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth sx={{ marginTop: '20px' }}>
                      <TextField
                        minRows={5}
                        maxRows={15}
                        multiline
                        label={'Poznámka'}
                        value={finalNote}
                        onChange={(event) => {
                          setFinalNote(event.target.value)
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )
              }
              confirmAction={(closeModal) => {
                setNotDeliveredRequest.executeRequest()
                closeModal()
              }}
            />
            <Button fullWidth variant={'contained'} size={'large'} color={'error'} onClick={() => { modalCtx.openModal('notDeliveredConfirm') }}>
              NEDORUČENO
            </Button>
          </Grid>
          {detail.status !== 4 &&
            <>
              <Grid item xs={6}>
                <ConfirmModal
                  name={'notDeliveredConfirmSMS'}
                  title={<Typography>Odložení zásilky s SMS</Typography>}
                  content={<Typography>Opravdu chcete zásilku označit jako <b>odloženou</b> a odeslat <b>SMS</b>?</Typography>}
                  confirmAction={(closeModal) => {
                    setDeferredSMSRequest.executeRequest()
                    closeModal()
                  }}
                />
                <Button fullWidth variant={'contained'} size={'large'} color={'warning'} onClick={() => { modalCtx.openModal('notDeliveredConfirmSMS') }}>
                  ODLOŽENO
                </Button>
              </Grid>
            </>
          }
          <Grid item xs={6}>
            <ConfirmModal
              name={'smsModal'}
              title={<Typography>Odeslaní SMS</Typography>}
              content={
                <Grid container direction={'column'} spacing={1}>
                  <Grid item>
                    <SelectSMSTemplate templates={smsTemplates} onSelect={(key) => { setFinalSMS(key) }}/>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth sx={{ marginTop: '20px' }}>
                      <TextField
                        minRows={5}
                        maxRows={15}
                        multiline
                        label={'SMS'}
                        value={finalSMS}
                        onChange={(event) => {
                          setFinalSMS(event.target.value)
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
              confirmActionsArea={(confirmProps) => (
                <Grid container direction={'row'} sx={{
                  justifyContent: 'space-between',
                  padding: 5
                }}>
                  <Grid item>
                    <Button variant={'contained'} color={'success'} onClick={() => {
                      confirmProps.confirmAction(confirmProps.closeModal)
                    }}>
                      ODESLAT
                    </Button>
                  </Grid>
                  <Button variant={'contained'} color={'error'} onClick={() => { confirmProps.closeModal() }}>
                    ZRUŠIT
                  </Button>
                </Grid>
              )}
              disableBaseActions={true}
              confirmAction={(closeModal) => {
                smsTemplateSendRequest.executeRequest()
                closeModal()
              }}
            />
            <Button fullWidth variant={'contained'} size={'large'} color={'info'} onClick={() => { modalCtx.openModal('smsModal') }}>
              SMS
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PackageContext.Provider>
  )
}
