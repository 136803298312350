import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteKeys } from '../components/router/Routes'


export const NotFoundPage = () => {
  return (
    <Grid
      container
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      minHeight={'100vh'}
      spacing={2}
    >
      <Grid item>
        <Typography variant={'h4'}>
          Stránka nenalezena
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Omlouváme se, ale na tento problém neexistuje žádná pilulka.
          Vypadá to, že stránka neexistuje nebo byla přemístěná.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant={'contained'}
          fullWidth color={'primary'}
          size={'large'}
          component={Link}
          to={RouteKeys.basePath}
        >
          Přejít na úvodní stránku
        </Button>
      </Grid>
    </Grid>
  )
}
