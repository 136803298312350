import { asyncFetch } from './asyncFetch'
import type HttpApiCallError from './HttpApiCallError'
import { NotificationService } from '../../services/Notification'
import { localStorageUserKey, UserContext } from '../../providers/UserProvider'
import { useContext, useState } from 'react'


export enum RequestType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const getBaseRequestConfig = (): RequestInit => {

  let config: RequestInit = {
    method: RequestType.GET,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const data = localStorage.getItem(localStorageUserKey)
  if (data) {
    const parsedData = JSON.parse(data)

    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${String(parsedData.token)}`
      }
    }
  }


  return config
}

export interface CreateRequest<ResponseModel> {
  type?: RequestType
  url: string
  body?: BodyInit | null
  requestOptions?: RequestInit
  onSuccess?: (response: ResponseModel) => void
  onError?: (error: HttpApiCallError) => void
  onFinish?: () => void
}


function processConfig<ResponseModel> (options: CreateRequest<ResponseModel>): RequestInit {
  let config = getBaseRequestConfig()
  if (options.requestOptions) {
    config = {
      ...config,
      ...options.requestOptions
    }
  }
  if (options.type) {
    config = {
      ...config,
      method: options.type
    }
  }

  if (options.body) {
    config = {
      ...config,
      body: options.body
    }
  }

  return config
}


export function useRequest<ResponseModel> (options: CreateRequest<ResponseModel>) {

  const [loading, setLoading] = useState(false)
  const userContext = useContext(UserContext)

  async function executeRequest (executeOptions?: Omit<CreateRequest<ResponseModel>, 'url'>) {
    const config = processConfig({ ...options, ...executeOptions })
    try {
      setLoading(true)
      const response = await asyncFetch(options.url, config)
      options.onSuccess?.(response)
    } catch (e) {
      const error = e as HttpApiCallError
      if (error.statusCode === 401) {
        userContext.logout()
      }
      if (options.onError == null) {
        let message = error.message
        if (!message) {
          message = error.textResponse ?? ''
        }
        if (!message) {
          message = 'Sys. error'
        }
        NotificationService.createNotification(message, {
          variant: 'error'
        })
        setLoading(false)
        options.onFinish?.()
        return
      }
      options.onError?.(error)
    }
    setLoading(false)
    options.onFinish?.()
  }

  return { executeRequest, loading }
}
